// ** React Imports
import React, { useEffect, useRef } from "react";

// ** Mui Imports
import { Box } from "@mui/material";

// ** Library Imports
import mapboxgl from "mapbox-gl";

// ** Css Imports
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "../../App.css";

// ** Utility Imports
import { loadAllMapImages, setMapBasicConfig } from "../../utils/map-util";
import { setComplaintMapLayer } from "../../utils/complaints-util";
import { setHexMapLayer } from "../../utils/hex-util";
import { setCheckinMapLayer } from "../../utils/checkin-util";
import {
  handleSetFilterOpen,
  handleSetSource,
} from "../../redux/slices/filter";

// ** Constant Imports
import {
  DEFAULT_LOCATION,
  MAPBOX_ACCESS_TOKEN,
  MAPBOX_STYLE,
} from "../../constant/constant";
import { getCustomMarker } from "../../utils/utils";
import FilterButton from "../common/FilterButton";
import { useDispatch, useSelector } from "react-redux";

// ** Mapbox Setup
mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

const EarthMapBox = () => {
  const dispatch = useDispatch();

  const filterOpen = useSelector((state) => state.filter.filterOpen);

  const source = useSelector((state) => state.filter.source);
  // const time = useSelector((state) => state.filter.time);
  // ** Refs
  const clickedMarkerRef = useRef(null);
  const shouldShowFilterButton = useRef(null);
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: "map",
      projection: "globe",
      style: MAPBOX_STYLE,
      center: [72, 23],
      zoom: 7,
      // center: DEFAULT_LOCATION,
      // zoom: 13,
      minZoom: 3,
      maxzoom: 21,
    });

    // ** Set current user location marker
    const userCurrentMarker = new mapboxgl.Marker(getCustomMarker("user"))
      .setLngLat(DEFAULT_LOCATION)
      .addTo(map);
    userCurrentMarker.setDraggable(false);

    map.on("load", async () => {
      // ** added fog and set map background stars
      setMapBasicConfig(map);

      // ** Load all map images and set layers
      await loadAllMapImages(map, () => {
        setComplaintMapLayer(map, source);
        setHexMapLayer(map);
        setCheckinMapLayer(map, clickedMarkerRef);
      });
    });

    // ** the "idele" state is called when all the frames of the map have loaded
    map.on("idle", () => {
      if (
        !map.getLayer("hexagon_points") ||
        !map.getLayer("checkin_points") ||
        !map.getLayer("complaints_points")
      ) {
        return;
      }
    });
    // ** Set Map Layers from window
    const setMapLayerType = (type) => {
      if (type === "complaints") {
        shouldShowFilterButton.current.style.display = "block"; // Update ref based on layer type
      } else {
        shouldShowFilterButton.current.style.display = "none";
      }
      switch (type) {
        case "venues":
          map.setLayoutProperty("checkin_points", "visibility", "visible");
          map.setLayoutProperty("hexagon_points", "visibility", "none");
          map.setLayoutProperty("complaints_points", "visibility", "none");
          break;
        case "street_levels":
          map.setLayoutProperty("checkin_points", "visibility", "none");
          map.setLayoutProperty("hexagon_points", "visibility", "visible");
          map.setLayoutProperty("complaints_points", "visibility", "none");
          break;
        case "complaints":
          map.setLayoutProperty("checkin_points", "visibility", "none");
          map.setLayoutProperty("hexagon_points", "visibility", "none");
          // setComplaintMapLayer(map);
          map.setLayoutProperty("complaints_points", "visibility", "visible");
          // layers.forEach((layer) => {
          //   if (layer.id.startsWith("layer-")) {
          //     map.setLayoutProperty(layer.id, "visibility", "visible");
          //   }
          // });
          break;
        default:
          break;
      }
    };
    window.setMapLayerType = setMapLayerType;
    // ** Set user's location marker
    const setloaction = (lng, lat, isZoom = true) => {
      if (isZoom) {
        map.flyTo({
          center: [lng, lat],
          zoom: 13,
          essential: true,
        });
      }
      userCurrentMarker.setLngLat([lng, lat]);
    };
    window.setLoaction = setloaction;
  }, [source]);
  const setFilter = (sourceOfNoise = "") => {
    dispatch(handleSetSource(sourceOfNoise));
    // dispatch(handleSetTimeFrame(timeFrame));
  };
  window.setFilter = setFilter;
  return (
    <React.Fragment>
      <Box sx={{ display: "block" }}>
        {/* {shouldShowFilterButton.current && ( */}
        <FilterButton
          shouldShowFilterButton={shouldShowFilterButton}
          onClick={() => dispatch(handleSetFilterOpen(!filterOpen))}
        />
        {/* // )} */}
        <Box
          id="map"
          style={{
            width: "100%",
            height: "100%",
            position: "fixed",
            overflow: "hidden",
          }}
        />
      </Box>
    </React.Fragment>
  );
};

export default EarthMapBox;
