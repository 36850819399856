// ** React Router Imports
import { createBrowserRouter } from "react-router-dom";

// ** Components Imports
import App from "../App";
import ErrorBoundary from "../components/common/ErrorBoundary";

const router = createBrowserRouter([
  {
    path: "*",
    element: <App />,
    errorElement: <ErrorBoundary />,
  },
]);

export default router;
