import React from "react";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/joy";
import { Controller } from "react-hook-form";
import { SOURCE_OF_NOISE } from "../../../constant/constant";

const SourceOfNoise = ({ control, sourceOfNoise }) => {
  return (
    <FormControl sx={{ width: "100%" }}>
      <FormLabel
        sx={{
          typography: "title-md",
          fontWeight: "semi-bold",
          mb: 1.5,
          ml: 1,
          fontSize: "18px",
        }}
      >
        Source of Noise
      </FormLabel>
      <div className="px-1" role="group" aria-labelledby="rank">
        {/* <List
          orientation="horizontal"
          wrap
          // sx={{
          //   "--List-gap": "8px",
          //   "--ListItem-radius": "20px",
          //   "--ListItem-minHeight": "32px",
          //   "--ListItem-gap": "12px",
          //   display: "grid",
          //   gridTemplateColumns:
          //     "repeat(auto-fill, minmax(calc(50% - 4px), 1fr))",
          //   "@media (max-width: 600px)": {
          //     gridTemplateColumns: "1fr",
          //   },
          // }}
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
          }}
        >
          {SOURCE_OF_NOISE.map((item) => (
            <FormControl key={item}>
              <ListItem
                sx={{
                  borderRadius: "32px",
                  justifyContent: "center",
                  height: "48px",
                  padding: "12px 24px 12px 24px",
                }}
              >
                <Controller
                  name="sourceOfNoise"
                  control={control}
                  defaultValue={sourceOfNoise}
                  render={({ field }) => (
                    <Checkbox
                      size="sm"
                      disableIcon
                      overlay
                      sx={{
                        ".MuiCheckbox-action": {
                          borderRadius: "32px",
                          height: "48px",
                          border: "none",
                          justifyContent: "center",
                          display: "flex",

                          backgroundColor: field.value.includes(item)
                            ? "#001AFF"
                            : "white",
                          ":active": {
                            backgroundColor:
                              field.value.includes(item) && "#001AFF",
                          },
                          ":hover": {
                            backgroundColor:
                              field.value.includes(item) && "#001AFF",
                          },
                        },

                        fontSize: "16px",
                      }}
                      label={item}
                      checked={field.value.includes(item)}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        const newValues = isChecked
                          ? [...field.value, item]
                          : field.value.filter((text) => text !== item);
                        field.onChange(newValues);
                      }}
                    />
                  )}
                />
              </ListItem>
            </FormControl>
          ))}
        </List> */}
        <RadioGroup value={sourceOfNoise}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1.5,
              flexWrap: "wrap",
            }}
          >
            {SOURCE_OF_NOISE.map((item) => (
              <Card
                key={item.name}
                sx={{
                  color: sourceOfNoise === item.value ? "white" : "0D0E10",
                  bgcolor: sourceOfNoise === item.value ? "#001AFF" : "#ffffff",
                  justifyContent: "center",
                  "&:hover": {
                    bgcolor:
                      sourceOfNoise === item.value
                        ? "001AFF"
                        : "background.level1",
                  },
                  borderRadius: "32px",
                  border: "none",
                  height: "48px",
                  padding: "12px 24px 12px 24px",
                }}
              >
                <CardContent>
                  {item.icon}
                  <Typography
                    sx={{
                      color: sourceOfNoise === item.value ? "white" : "0D0E10",
                      // fontWeight: 500,
                      fontSize: "16px",
                    }}
                  >
                    {item.name}
                  </Typography>
                </CardContent>
                <Controller
                  name="sourceOfNoise"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Radio
                      {...field}
                      disableIcon
                      overlay
                      checked={sourceOfNoise === item.value}
                      variant="solid"
                      value={item.value}
                      sx={{
                        mt: -2,
                        ".MuiRadio-action": {
                          bgcolor: "transparent",
                          ":hover": {
                            bgcolor: "transparent",
                          },
                        },
                      }}
                    />
                  )}
                />
              </Card>
            ))}
          </Box>
        </RadioGroup>
      </div>
    </FormControl>
  );
};

export default SourceOfNoise;
