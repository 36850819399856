// ** React Imports
import React, { useState } from "react";

// ** Mui Imports
import { Box, Button, Stack } from "@mui/joy";
import { Typography, useMediaQuery } from "@mui/material";

// ** Third Party Imports
import { useForm } from "react-hook-form";
import { LoaderIcon } from "lucide-react";
import {
  handleSetFilterOpen,
  handleSetSource,
  handleSetTimeFrame,
} from "../../../redux/slices/filter";

// ** Constant Imports
import { IS_MOBILE_MEDIA_QUERY } from "../../../constant/constant";

// ** Component Imports
import SourceOfNoise from "./SourceOfNoise";
import { useDispatch } from "react-redux";

const FilterFormProvider = () => {
  const dispatch = useDispatch();
  const [loading] = useState(false);

  const formValues = {
    sourceOfNoise: null,
    timeFrame: null,
  };
  const isMobile = useMediaQuery(IS_MOBILE_MEDIA_QUERY);
  const {
    handleSubmit,
    control,
    reset,
    watch,
    // formState: { errors },
  } = useForm({
    defaultValues: formValues,
    // resolver: yupResolver(validationSchema),
  });
  const { sourceOfNoise } = watch();
  // const resetData = () => {
  //   reset({
  //     timFrame: null,
  //     sourceOfNoise: [],
  //   });
  // };
  const onSubmit = async (data) => {
    dispatch(handleSetFilterOpen(false));
    dispatch(handleSetSource(data?.sourceOfNoise ? data?.sourceOfNoise : ""));
    dispatch(handleSetTimeFrame(data?.timeFrame ? data?.timeFrame : ""));
    // dispatch(handleSetReset());
  };

  //   useEffect(() => {
  //     if (shouldResetForm) {
  //       resetData();
  //       dispatch(handleFilterFormResetComplete());
  //     }
  //     // eslint-disable-next-line
  //   }, [shouldResetForm, dispatch]);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="mt-[3rem] min-[396px]:mt-[3rem] min-[500px]:mt-[3rem] min-[600px]:mt-[3rem] sm:mt-12 mb-20 sm:mb-6 min-[396px]:mb-[2rem] min-[500px]:mb-[1rem] flex flex-col self-start gap-12 h-full w-full justify-center max-h-[900px]"
      style={{
        minHeight: isMobile ? "700px" : undefined, // Conditionally set min-height based on isMobile
        maxHeight: !isMobile ? "747px" : undefined,
      }}
    >
      <Typography sx={{ fontWeight: "500", fontSize: "34px", ml: 1 }}>
        Filter Complaints
      </Typography>
      {/* <TimeFrame control={control} timeFrame={timeFrame} /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          flexWrap: "wrap",
        }}
      >
        <SourceOfNoise control={control} sourceOfNoise={sourceOfNoise} />

        <Stack
          direction="column"
          justifyContent="center"
          useFlexGap
          spacing={1}
        >
          <Button
            sx={{
              backgroundColor: "#0D0E10",
              color: "white",
              width: "100%",
              height: "56px",
              fontSize: "16px",
              fontWeight: "500",
              borderRadius: "32px",
              ":hover": { backgroundColor: "#0D0E10" },
            }}
            disabled={loading}
            type="submit"
          >
            {loading && (
              <LoaderIcon
                size={18}
                color="black"
                className="me-2 animate-spin"
              />
            )}
            Apply
          </Button>
          <Button
            sx={{
              border: "1px solid #0D0E10",
              backgroundColor: "white",
              color: "#0D0E10",
              width: "100%",
              height: "56px",
              fontSize: "16px",
              fontWeight: "500",
              borderRadius: "32px",
              ":hover": { backgroundColor: "background.level1" },
            }}
            onClick={async () => {
              reset();
              dispatch(handleSetSource(""));
              dispatch(handleSetTimeFrame(""));
            }}
          >
            Clear
          </Button>
        </Stack>
      </Box>
    </form>
  );
};

export default FilterFormProvider;
