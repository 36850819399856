// ** Redux Imports
import { combineReducers } from "redux";

// ** Redux Slices
import filterReducer from "./slices/filter";

// slices

// ----------------------------------------------------------------------

export const createNoopStorage = () => ({
  getItem(_key) {
    return Promise.resolve(null);
  },
  setItem(_key, value) {
    return Promise.resolve(value);
  },
  removeItem(_key) {
    return Promise.resolve();
  },
});

export const rootReducer = combineReducers({
  filter: filterReducer,
});
