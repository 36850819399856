import React from "react";

const ErrorBoundary = () => {
  return (
    <div
      className="bg-red-50 min-h-screen border text-red-700 px-4 py-3 rounded relative flex flex-col justify-center items-center"
      role="alert"
    >
      <strong className="font-bold">Oops!</strong>
      <span className="block sm:inline text-3xl">Something went wrong</span>
    </div>
  );
};

export default ErrorBoundary;
