import { IconButton } from "@mui/material";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

const FilterButton = (props) => {
  const matches = useMediaQuery("(min-width:600px)"); // Adjust the breakpoint as needed

  if (!matches) {
    return null; // Return null to not render the button on small screens
  }
  return (
    <>
      <IconButton
        ref={props.shouldShowFilterButton}
        id="switch"
        size="small"
        className="!absolute top-3 left-2 sm:hidden"
        sx={{
          backgroundColor: "white",
          ":hover": {
            backgroundColor: "white",
          },
          zIndex: "1",
          border: "1px solid gray",
        }}
        {...props}
      >
        <img
          src="/assets/filterImage.png"
          className="w-[22px] h-[22px]"
          alt="switch"
        />
      </IconButton>
    </>
  );
};

export default FilterButton;
