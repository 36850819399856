// ** Hexagon Layer Code here
export const setHexMapLayer = (map) => {
  map.addSource("hexagon_points", {
    type: "vector",
    tiles: [
      "https://staging.silencio.store/map/tiles/datapoints/{z}/{x}/{y}/cache.mvt",
    ],
    minzoom: 3,
    maxzoom: 22,
  });

  map.addLayer({
    id: "hexagon_points",
    type: "fill",
    source: "hexagon_points",
    "source-layer": "datapoints",
    paint: {
      "fill-color": ["get", "color"],
      "fill-opacity": 0.4,
    },
    layout: {
      visibility: "none",
    },
    minzoom: 3,
    maxzoom: 22,
  });
};
