// ** React Import
import * as React from "react";

// ** Mui Imports
import Drawer from "@mui/joy/Drawer";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";

// ** Custom Component Imports
import FilterFormProvider from "./form";

const DesktopFilter = ({ open, closeModal }) => {
  return (
    <Drawer size="md" open={open} onClose={closeModal}>
      <Sheet
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          height: "100%",
          overflow: "auto",
          bgcolor: "#f6f6f8",
        }}
      >
        <ModalClose
          sx={{
            bgcolor: "white",
            borderRadius: "32px",
            height: "48px",
            width: "64px",
          }}
        />
        <FilterFormProvider />
      </Sheet>
    </Drawer>
  );
};

export default DesktopFilter;
