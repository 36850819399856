// ** Redux Imports
import { configureStore } from "@reduxjs/toolkit";
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from "react-redux";

// ** Root Reducer Import
import { rootReducer } from "./root-reducer";

// ----------------------------------------------------------------------

export const store = configureStore({
  reducer: rootReducer,
});

export const useSelector = useAppSelector;

export const useDispatch = () => useAppDispatch();
