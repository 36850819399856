// ** React Imports
import * as React from "react";

// ** Redux Imports
import { useDispatch, useSelector } from "react-redux";
import { handleSetFilterOpen } from "../../redux/slices/filter";

// ** Mui Imports
import { useMediaQuery } from "@mui/material";

// ** Custom Component Imports
import DesktopFilter from "./DesktopFilter";
import MobileFilter from "./MobileFilter";

// ** Constant Imports
import { IS_MOBILE_MEDIA_QUERY } from "../../constant/constant";

const FilterView = () => {
  const { open } = useSelector((state) => state.filter);
  const isMobile = useMediaQuery(IS_MOBILE_MEDIA_QUERY);

  const dispatch = useDispatch();
  const closeModal = () => dispatch(handleSetFilterOpen(false));

  return isMobile ? (
    <MobileFilter open={open} closeModal={closeModal} />
  ) : (
    <DesktopFilter open={open} closeModal={closeModal} />
  );
};

export default FilterView;
