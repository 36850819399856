export const getCustomMarker = (type) => {
  if (type === "user") {
    const destinationMarker = document.createElement("div");
    const childMarker = document.createElement("div");
    const imgElement = document.createElement("img");
    imgElement.className = "marker-image";
    imgElement.src = "/assets/navigation.png";
    childMarker.appendChild(imgElement);
    destinationMarker.appendChild(childMarker);
    childMarker.className = "location-circle";
    destinationMarker.className = "parent-circle";
    return destinationMarker;
  }
  // add other marker and types bellow
};
