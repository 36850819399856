// ** React Import
import * as React from "react";

// ** Mui Imports
import Drawer from "@mui/joy/Drawer";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";

// ** Custom Components Import
import FilterFormProvider from "./form";

const MobileFilter = ({ open, closeModal }) => {
  const filterContainerRef = React.useRef(null);

  return (
    <Drawer
      size="lg"
      open={open}
      onClose={closeModal}
      ref={filterContainerRef}
      anchor="bottom"
      sx={{
        bgcolor: "#f6f6f8",
        ".MuiDrawer-content": {
          height: "100%",
          bgcolor: "#f6f6f8",
        },
      }}
    >
      <Sheet
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          height: "100%",
          overflow: "auto",
          border: "none",
          bgcolor: "#f6f6f8",
        }}
      >
        <ModalClose
          sx={{
            bgcolor: "white",
            borderRadius: "32px",
            height: "48px",
            width: "64px",
          }}
        />
        <div>
          <FilterFormProvider />
        </div>
      </Sheet>
    </Drawer>
  );
};

export default MobileFilter;
