// ** Mapbox Imports
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

// ** Style Imports
import "./App.css";

// ** Constant Imports
import { MAPBOX_ACCESS_TOKEN } from "./constant/constant";

// ** Custom Components Imports
import EarthMapBox from "./components/EarthMapBox/EarthMapBox";
import FilterView from "./view/FilterView/filter-view";

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

function App() {
  return (
    <>
      <EarthMapBox />
      <FilterView />
    </>
  );
}

export default App;
