import { getComplaintsUrl } from "../constant/constant";

export const setComplaintMapLayer = (map, source) => {
  const lat = map.getCenter().lat.toFixed(4);
  const lng = map.getCenter().lng.toFixed(4);

  map.addSource("complaints_points", {
    type: "vector",
    tiles: [getComplaintsUrl(lat, lng, source)],
    cluster: true,
    clusterMaxZoom: 100, // Max zoom to cluster points on
    clusterRadius: 10,
    // maxzoom: 21,
  });
  map.addLayer({
    id: "complaints_points",
    type: "symbol",
    source: "complaints_points",
    "source-layer": `noise_complain`,
    filter: ["has", "point_count"],
    layout: {
      "icon-image": [
        "case",
        ["!=", ["get", "point_count"], 1],
        "warning",
        ["==", ["get", "noise_source"], "music"],
        "music1",
        ["get", "noise_source"],
      ],
      "icon-size": 0.25,
      "text-field": [
        "case",
        [">", ["get", "point_count"], 10000],
        [
          "concat",
          ["slice", ["to-string", ["/", ["get", "point_count"], 1000]], 0, 4],
          "K",
        ],
        ["concat", ["to-string", ["get", "point_count"]]],
      ],
      "text-size": 12,
      "text-anchor": "top",
      "text-offset": [0.9, -0.7],
      "text-justify": "auto",
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      visibility: "visible",
    },
    paint: {
      "text-color": "#0D0E10",
    },
  });
  // map.on("click", "complaints_points", (e) => {
  //   console.log(e.features[0].properties.noise_source);
  // });
};
