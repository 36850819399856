import { imagesToLoad } from "../constant/constant";

export const loadAllMapImages = async (map, loadImages) => {
  try {
    for (const image of imagesToLoad) {
      await loadImageAsync(map, image.name, image.url);
    }

    loadImages();
  } catch (error) {
    console.error("Error loading images:", error);
  }
};

const loadImageAsync = (map, name, url) => {
  return new Promise((resolve, reject) => {
    map.loadImage(url, (error, image) => {
      if (error) {
        reject(error);
      } else {
        map.addImage(name, image);
        resolve();
      }
    });
  });
};

// ** Send checkin data to mobile
export const sendCheckinDataToMobile = (id) => {
  const data = {
    id,
  };
  window?.Android?.sendCheckinDataToMobile(JSON.stringify(data));
  window?.webkit?.messageHandlers.sendCheckinDataToMobile.postMessage(
    JSON.stringify(data)
  );
  console.log("Website data sent successfully");
};

// ** Set zoom on click pin in vennues
export const setZoomOnClick = (map, lat, lang) => {
  if (map.getZoom() <= 11) {
    map.flyTo({
      center: [lang, lat],
      zoom: 13,
      essential: true,
      duration: 1000,
    });
  }
};

// ** Set map background stars and fog
export const setMapBasicConfig = (map) => {
  map.setFog({
    color: "rgb(186, 210, 235)",
    "high-color": "#5D5FEF",
    "horizon-blend": 0.02,
    "space-color": "rgb(11, 11, 25)",
    "star-intensity": 0.6,
  });
  map.on("mouseenter", "checkinlayer", () => {
    map.getCanvas().style.cursor = "pointer";
  });
  map.on("mouseleave", "checkinlayer", () => {
    map.getCanvas().style.cursor = "";
  });
};
