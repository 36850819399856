export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoicWtpbm5vdmF0aW9ucyIsImEiOiJjbHAwdmlzMm8wOTh3Mmpwamp0M2VoMXdiIn0.9hgpMb1bYhuHpmbBEHDIuQ";

export const getTilesUrl = (
  minDbValue = 0,
  maxDbValue = 300,
  categories = "all"
) =>
  `${process.env.REACT_APP_API_BASE_URL}/map/tiles/checkins/{z}/{x}/{y}/point.mvt/?minDbValue=${minDbValue}&maxDbValue=${maxDbValue}&categories=${categories}`;

export const getComplaintsUrl = (lat, lng, source) => {
  if (source?.length > 0) {
    return `${process.env.REACT_APP_API_BASE_URL}/map/tiles/complaints/{z}/{x}/{y}/noise-complain.mvt?source=${source}&latitude=${lat}&longitude=${lng}`;
  }
  return `${process.env.REACT_APP_API_BASE_URL}/map/tiles/complaints/{z}/{x}/{y}/noise-complain.mvt?latitude=${lat}&longitude=${lng}`;
};

export const MAPBOX_STYLE =
  "mapbox://styles/qkinnovations/clkqwwhmr00o601pbh131dmq2";
export const IS_MOBILE_MEDIA_QUERY = "(max-width:768px)";
export const DEFAULT_LOCATION = [11.581981, 48.135124]; // (Munich [lat,lng])
export const SOURCE_OF_NOISE = [
  {
    name: "Traffic",
    value: "traffic",
  },
  {
    name: "Music",
    value: "music",
  },
  {
    name: "Siren",
    value: "siren",
  },
  {
    name: "Animals",
    value: "animal",
  },
  {
    name: "Neighbours",
    value: "neighbours",
  },
  {
    name: "Train",
    value: "train",
  },
  {
    name: "Construction",
    value: "construction",
  },
  {
    name: "Industrial",
    value: "industrial",
  },
  {
    name: "Airplane",
    value: "airplane",
  },
  {
    name: "Ships",
    value: "ships",
  },
  {
    name: "Nightlife",
    value: "nightlife",
  },
  {
    name: "Events",
    value: "events",
  },
  {
    name: "Other",
    value: "other",
  },
];

export const imagesToLoad = [
  { name: "warning", url: "/assets/Pin.png" },
  { name: "teal", url: "/assets/teal-pin.png" },
  { name: "orange", url: "/assets/orange-checkin.png" },
  { name: "blue", url: "/assets/blue-checkin.png" },
  { name: "bluePinFilled", url: "/assets/blue-pin-filled.png" },
  { name: "blueDot", url: "/assets/blue-dot.png" },
  { name: "orangeDot", url: "/assets/orange-dot.png" },
  { name: "tealDot", url: "/assets/teal-dot.png" },
  { name: "airplane", url: "/assets/airplane.png" },
  { name: "animal", url: "/assets/animal.png" },
  { name: "construction", url: "/assets/construction.png" },
  { name: "events", url: "/assets/event.png" },
  { name: "industrial", url: "/assets/industrial.png" },
  { name: "music1", url: "/assets/music1.png" },
  { name: "neighbours", url: "/assets/neighbours.png" },
  { name: "nightlife", url: "/assets/nightlife.png" },
  { name: "ships", url: "/assets/ships.png" },
  { name: "siren", url: "/assets/siren.png" },
  { name: "traffic", url: "/assets/traffic.png" },
  { name: "train", url: "/assets/train.png" },
  { name: "other", url: "/assets/other.png" },
];
