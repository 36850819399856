import { getTilesUrl } from "../constant/constant";
import { sendCheckinDataToMobile, setZoomOnClick } from "./map-util";

export const setCheckinMapLayer = (map, clickedMarkerRef) => {
  map.addSource("checkin_points", {
    type: "vector",
    tiles: [getTilesUrl(0, 200, "all")],
    minzoom: 3,
    maxzoom: 21,
  });

  map.addLayer({
    id: "checkin_points",
    type: "symbol",
    source: "checkin_points",
    "source-layer": "checkin",
    layout: {
      "icon-image": [
        "case",
        ["<=", ["round", ["get", "averagedb"]], 59],
        "teal",
        ["<=", ["round", ["get", "averagedb"]], 69],
        "blue",
        "orange",
      ],
      "icon-size": 1,
      "text-field": ["concat", ["round", ["get", "averagedb"]], " dB-A"],
      "text-size": 14,
      "text-anchor": "top",
      "text-offset": [0.4, -0.95],
      "text-justify": "auto",
      "text-font": ["DIN Offc Pro Medium"],
      visibility: "none",
    },
    paint: {
      "text-color": "#0D0E10",
    },
    minzoom: 3,
    maxzoom: 21,
  });
  // **  on clicking map remove blue color of pin code here
  map.on("click", () => {
    if (map.getZoom() <= 11) {
      map.setLayoutProperty("checkin_points", "icon-image", [
        "case",
        ["<=", ["round", ["get", "averagedb"]], 59],
        "tealDot",
        ["<=", ["round", ["get", "averagedb"]], 69],
        "blueDot",
        "orangeDot",
      ]);
    } else {
      map.setLayoutProperty("checkin_points", "icon-image", [
        "case",
        ["<=", ["round", ["get", "averagedb"]], 59],
        "teal",
        ["<=", ["round", ["get", "averagedb"]], 69],
        "blue",
        "orange",
      ]);
      map.setPaintProperty("checkin_points", "text-color", "#0D0E10");
    }
  });

  // ** on clicking pin  set blue color of pin code here
  map.on("click", "checkin_points", (e) => {
    const { id, latitude, longitude } = e.features[0].properties;
    clickedMarkerRef.current = e.features[0].properties.id;
    if (clickedMarkerRef.current) {
      map.setLayoutProperty("checkin_points", "icon-image", [
        "match",
        ["get", "id"],
        clickedMarkerRef.current,
        "bluePinFilled",
        [
          "case",
          ["<=", ["round", ["get", "averagedb"]], 59],
          "teal",
          ["<=", ["round", ["get", "averagedb"]], 69],
          "blue",
          "orange",
        ],
      ]);
      map.setPaintProperty("checkin_points", "text-color", [
        "match",
        ["get", "id"],
        clickedMarkerRef.current,
        "#FFFFFF",
        "#0D0E10",
      ]);
      sendCheckinDataToMobile(id);
      setZoomOnClick(map, latitude, longitude);
    }
  });
  // ** on zoom icon change and textfied change of layer code here
  map.on("zoom", (e) => {
    if (map.getZoom() <= 11) {
      map.setLayoutProperty("checkin_points", "text-field", "");
      map.setLayoutProperty("checkin_points", "icon-image", [
        "case",
        ["<=", ["round", ["get", "averagedb"]], 59],
        "tealDot",
        ["<=", ["round", ["get", "averagedb"]], 69],
        "blueDot",
        "orangeDot",
      ]);
    } else {
      map.setLayoutProperty("checkin_points", "text-field", [
        "concat",
        ["round", ["get", "averagedb"]],
        " dB-A",
      ]);
      map.setLayoutProperty("checkin_points", "icon-image", [
        "case",
        ["<=", ["round", ["get", "averagedb"]], 59],
        "teal",
        ["<=", ["round", ["get", "averagedb"]], 69],
        "blue",
        "orange",
      ]);
      map.setPaintProperty("checkin_points", "text-color", "#0D0E10");
    }
  });
};
