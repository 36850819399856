import { createSlice } from "@reduxjs/toolkit";

// Async action to fetch nearby check-ins
// const fetchNearbyCheckIns = createAsyncThunk(
//   "map/fetchNearbyCheckIns",
//   async (_, { getState }) => {
//     const { lat, lng } = getState().locate.user;
//     const fetchedData = await axios.get(
//       getCheckInApiUrl(lat, lng, 50000, 0, 200, "closest", "all")
//     );
//     return fetchedData.data;
//   }
// );

// ----------------------------------------------------------------------
const initialState = {
  open: false,
  source: "",
  time: "",
};

const slice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    handleSetFilterOpen: (state, action) => {
      state.open = action.payload;
    },
    handleSetSource: (state, action) => {
      state.source = action.payload;
    },
    handleSetTimeFrame: (state, action) => {
      state.time = action.payload;
    },
    handeResetForm: (state) => {
      state.source = "";
      state.time = "";
      state.open = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  handleSetFilterOpen,
  handleSetSource,
  handleSetTimeFrame,
  handleResetForm,
} = slice.actions;

// Export the async action
export {};
